.multi-column-component {
	&[data-has-multiple="true"] {
		.card-classic {
			background-color: transparent;

			.message-container {
				background: inherit;
				min-height: auto;
				padding: 0px 0 30px;

				@include break-min($medium4) {
					padding: 32px;
					grid-row: 1;
				}

				.title-text {
					& > :first-child {
						@include break-min($medium4) {
							@include font-size(3.6);
						}
					}
				}

				.btn {
					position: relative;
				}

				.body-text {
					margin-bottom: 20px;

					@include break-min($medium4) {
						margin-bottom: 30px;
					}
				}

				@include break-max($medium2) {
					padding: 0;

					.body-text {
						margin-bottom: 0;
					}
				}
			}
		}
	}
	&[data-child-count="2"] {
		@include break-min($medium4) {
			.card-classic {
				grid-template-columns: repeat(12, [col-start] 1fr);

				.message-container {
					grid-column: col-start 1 / span 12;
				}
			}
		}

		@include break-min($large4) {
			margin: 0 262px 60px;
		}
	}
}
