.list-view-cards {
	margin: 0 24px 42px;

	@include break-min($medium4) {
		text-align: center;
	}

	@include break-min($large1) {
		margin: 0 0 98px;
	}

	.title {
		margin: 0 0 38px;

		@include break-min($medium4) {
			margin: 0 0 48px;
		}

		> *:first-child {
			@include font-size(2.4);
			color: $blue;
			font-weight: $bold-weight;
			letter-spacing: -0.01em;
			margin: 0;

			@include break-min($medium4) {
				@include font-size(4.8);
				letter-spacing: -1px;
			}
		}
	}

	ul {
		@include grid-margin();
		display: block;
		list-style-type: none;
		margin: 0;
		padding: 0;

		@include break-min($medium4) {
			display: grid;
			gap: 24px;
		}

		@include break-min($huge4) {
			justify-content: center;
		}
	}

	li {
		margin: 0 0 30px;
		text-align: left;
		transition: all 0.3s ease-in-out;

		@include break-min($medium4) {
			grid-column: auto / span 4;
			margin: 0;
		}

		.img-container {
			border-radius: 32px;
			height: 188px;
			width: 100%;
			margin: 0 0 15px;
			overflow: hidden;
			position: relative;

			@include break-min($medium4) {
				height: 265px;
			}

			img {
				@include absolute-center();
				object-fit: cover;
				height: 100%;
				width: 100%;
			}
		}

		.title {
			@include font-size(2);
			color: $blue;
			display: block;
			font-weight: $bold-weight;
			line-height: lh(28, 20);
			letter-spacing: -0.01em;
			margin: 0 0 16px 0;

			@include break-min($large1) {
				margin: 0 0 24px 0;
			}
		}

		.btn-round {
			margin: auto;
			width: 192px;
		}
	}

	.btn-container {
		margin: 8px 0 0;
		text-align: center;

		@include break-min($medium4) {
			margin: 48px 0 0;
		}

		a {
			min-width: 135px;
		}
	}

	.see-more-container {
		@include break-min($large1) {
			display: none;
		}
	}

	&.withSeeMore {
		@include break-max($large1) {
			li:not(:first-child) {
				opacity: 0;
				display: none;
			}

			.btn-container {
				display: none;
			}

			&.open {
				li {
					opacity: 1;
					display: block;
				}

				.see-more-container {
					display: none;
				}

				.btn-container {
					display: block;
				}
			}
		}
	}
}
