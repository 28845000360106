.covid-sticky-nav-dropdown {
	background: $white;
	margin: auto;
	// sass-lint:disable no-vendor-prefixes
	-ms-overflow-style: none;
	// sass-lint:enable no-vendor-prefixes
	width: auto;
	z-index: 10000;

	@include break-max($large1) {
		position: fixed;
		width: 100%;
	}

	&::-webkit-scrollbar {
		display: none;
	}

	&.mobileOpen {
		.current-nav-item:after {
			transform: rotate(180deg);
		}

		.dropdown {
			.link {
				display: block;
			}

			&:after {
				transform: rotate(180deg);
			}
		}
	}

	&.desktopSticky {
		animation: topSlidedown 350ms ease-in-out;
		max-width: 2000px;
		position: fixed;
		top: 63px;
		width: 100%;

		.dropdown {
			max-width: none;
			width: 100%;

			.link.active {
				display: block;
				left: 0;
			}
		}
	}

	&.mobileSlickSlider {
		background: transparent;
		overflow-y: hidden;
		position: initial;
		z-index: 0;

		.current-nav-item {
			display: none;
		}

		.link {
			background: $white;
		}

		.dropdown {
			background: transparent;
			border-top: 0;
			display: block;
			max-height: none;
			overflow-y: hidden;
			padding: 0;
			z-index: 0;

			.slick-slide {
				margin: 0 16px 50px 0;

				&:last-child {
					margin: 0 0 50px 0;
				}
			}

			div {
				padding: 0;

				&:first-child {
					margin-top: 0;
				}

				&:after {
					background-image: none;
				}
			}

			.link {
				border-radius: 8px;
				border-top: 1px solid rgba(0, 0, 0, 0.2);
				box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
				height: 330px;
				margin-right: 16px;
				padding: 54px 33px;
				text-align: center;
				width: 100%;

				a {
					flex-direction: column;
					height: 100%;
					justify-content: center;
				}

				.img-wrapper {
					height: 125px;
					margin: 0 auto;
					width: 125px;
				}

				img {
					margin: 0 auto 0;
				}

				p {
					@include font-size(2.4);
					align-self: center;
					color: $blue;
					font-weight: $bold-weight;
					letter-spacing: -0.5px;
					line-height: lh(34, 24);
					margin: 10px auto 0;
				}
			}

			.scroll {
				display: none;
			}
		}

		.slick-dots {
			@extend .slick-slider-dots-grey;
		}
	}

	&.mobileNotSticky {
		background-image: linear-gradient(
			180deg,
			rgb(255, 255, 255) 50%,
			$warm-white 50%
		);
		padding: 0 24px;
		position: relative;

		.current-nav-item {
			display: none;
		}

		.dropdown {
			border-radius: 8px;
			box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
			display: flex;
			flex-direction: column;
			margin: 0 auto;

			@include break-min($medium2) {
				max-width: 65%;
			}

			.link {
				display: block;
				padding: 25px 0 25px 20px;

				.img-wrapper {
					min-width: 75px;
				}

				img {
					height: 68px;
					width: 68px;

					@include break-min($large1) {
						height: 100%;
						width: 100%;
					}
				}
			}
		}

		.scroll {
			display: none;
		}
	}

	&.desktopHidden {
		display: none;
	}

	.current-nav-item {
		box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
		cursor: pointer;
		padding: 22px 24px;
		position: relative;

		@include break-min($large1) {
			box-shadow: none;
			display: none;
		}

		button {
			@include font-size(1.6);
			background: transparent;
			border: 0;
			color: $blue;
			font-weight: bold;
			letter-spacing: -0.5px;
		}

		&:after {
			@include font-size(1.8);
			color: $red;
			content: "\e902";
			font-family: $icon;
			position: absolute;
			right: 26px;
			transition: 0.5s all ease;
		}
	}

	.scroll {
		display: none;
		@include break-min($large1) {
			background: $cool-white;
			border: 0;
			box-shadow: 0 0 4px $light-grey;
			height: 100%;
			position: absolute;
			right: 0;
			top: 0;
			width: 30px;

			&.rotate {
				&:after {
					transform: rotate(90deg);
				}
			}
		}

		&:after {
			color: $red;
			content: "\e902";
			font-family: $icon;
			height: 20px;
			left: 0;
			margin: auto;
			position: absolute;
			right: 0;
			top: 60px;
			transform: rotate(270deg);
			transition: 0.5s all ease;
			z-index: 100;
			width: 20px;
		}
	}
}
