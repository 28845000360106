.quote-module {
	margin-bottom: 32px;
	margin-top: 32px;

	@include break-min($large1) {
		@include grid-margin();
		margin-bottom: 48px;
		margin-top: 48px;
	}

	.quote-wrapper {
		background: $cool-white;
		color: $blue;
		width: fit-content;

		@include break-min($large1) {
			display: grid;
			gap: 24px;
			grid-template-columns: repeat(12, [col-start] 1fr);
			border-radius: 32px;
			overflow: hidden;
			margin-left: auto;
			margin-right: auto;
		}

		@include break-min($huge4) {
			grid-template-columns: repeat(12, [col-start] 104px);
		}
	}

	.quote-container {
		display: flex;
		flex-direction: column;
		grid-column: col-start 1 / span 12;
		justify-content: center;
		padding: 72px 40px;

		@include break-min($large1) {
			padding: 72px 48px;
			margin: 0 174px;
		}

		p {
			&:first-of-type {
				@include type-h2-refresh();
				position: relative;

				&:before,
				&:after {
					position: absolute;
				}

				&:before {
					content: "“";
					left: -15px;
					top: -20px;
				}

				&:after {
					content: "”";
					bottom: -20px;
					right: 0;
				}
			}

			&:nth-of-type(2) {
				@include type-h4-refresh();
				margin: 30px 0 0;
				text-align: right;
			}

			&:nth-of-type(3) {
				@include type-p3();
				margin: 5px 0 0;
				text-align: right;
			}
		}
	}

	.img-container {
		display: none;

		@include break-min($large1) {
			display: block;
			position: relative;
			height: 100%;
			width: 100%;
		}

		img {
			@include absolute-center();
			height: 100%;
			object-fit: cover;
			width: 100%;
		}
	}

	.blurb {
		margin: 0 24px;

		@include break-min($large1) {
			max-width: 500px;
		}

		p {
			@include type-p1-refresh();
		}
	}

	&.with-img {
		.quote-wrapper {
			.quote-container {
				@include break-min($large1) {
					grid-column: col-start 1 / span 7;
					margin: 0;
				}
			}

			.img-container {
				@include break-min($large1) {
					grid-column: col-start 8 / span 5;
				}
			}
		}
	}

	&.with-blurb {
		max-width: 1076px;
		margin: auto;

		@include break-min($large1) {
			align-items: center;
			display: flex;
		}

		.quote-wrapper {
			margin: 0 0 15px;

			@include break-min($large1) {
				margin: 0 15px 0 0;
			}

			@include break-min($huge4) {
				grid-template-columns: repeat(12, [col-start] 1fr);
			}

			.quote-container {
				@include break-min($large1) {
					margin: 0;
				}
			}
		}
	}

	&.no-quote {
		p {
			&:first-of-type {
				&:before {
					content: none;
				}

				&:after {
					content: none;
				}
			}
		}
	}
	&.align-left {
		.quote-container {
			text-align: left;

			p {
				&:nth-of-type(2) {
					text-align: left;
				}

				&:nth-of-type(3) {
					text-align: left;
				}
			}
		}
	}

	&.align-center {
		.quote-container {
			text-align: center;

			p {
				&:nth-of-type(2) {
					text-align: center;
				}
				&:nth-of-type(3) {
					text-align: center;
				}
			}
		}
	}
}
