.mod-mb-98 {
	margin-bottom: 54px !important;

	@include break-min($large1) {
		margin-bottom: 98px !important;
	}
}

.mod-mb-96 {
	margin-bottom: 40px !important;

	@include break-min($large1) {
		margin-bottom: 96px !important;
	}
}

.mod-mb-0 {
	margin-bottom: 0 !important;

	@include break-min($large1) {
		margin-bottom: 0 !important;
	}
}

.mod-mb-19 {
	margin-bottom: 0 !important;

	@include break-min($large1) {
		margin-bottom: 19px !important;
	}
}

.mod-mb-69 {
	margin-bottom: 40px !important;

	@include break-min($large1) {
		margin-bottom: 69px !important;
	}
}

.mod-mt-98 {
	margin-top: 54px !important;

	@include break-min($large1) {
		margin-top: 98px !important;
	}
}

.mod-pb-0 {
	padding-bottom: 0 !important;
}
