.news-callout {
	@include grid-margin();
	grid-gap: 24px;
	padding: 0;

	@include break-min($large1) {
		justify-content: center;
		grid-auto-flow: unset;
	}

	&.full-width-view {
		@include break-min($medium4) {
			gap: 0;
		}

		@include break-min($large4) {
			display: block !important;
			margin: auto;
		}

		li {
			@include break-min($medium4) {
				border-radius: 32px;
				margin: 0 0 45px 0;
			}

			.msg-container {
				@include break-min($medium4) {
					display: flex;
					flex: 1;
					flex-direction: column;
					height: auto;
					justify-content: center;
					padding: 51px 22px 51px 42px;
				}

				img {
					display: none;
				}
			}

			.img-container {
				@include break-min($medium4) {
					flex: 1;
					min-height: 262px;
				}
			}

			@include break-min($medium4) {
				display: flex;
				grid-column: auto / span 8;
				justify-content: center;
			}

			@include break-min($large4) {
				grid-column: auto / span 12;
			}
		}
	}

	li {
		border-radius: 16px;
		list-style-type: none;
		overflow: hidden;
		position: relative;
		grid-column: auto / span 4;

		@include break-min($large4) {
			grid-column: auto / span 6;
			margin: 0 0 45px;
		}

		.img-container {
			height: 172px;
			overflow: hidden;
			position: relative;

			@include break-min($large1) {
				height: 356px;
			}

			img {
				@include absolute-center();
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}

		.msg-container {
			background: $cool-white;
			display: flex;
			flex-direction: column;
			gap: 14px;
			padding: 26px 22px;
			position: relative;

			@include break-min($large1) {
				gap: 18px;
				height: 100%;
				padding: 42px;
			}

			.msg-headline p {
				@include font-size(1.4);
				color: $blue;
				font-weight: $bold-weight;
				letter-spacing: -0.5px;
				line-height: lh(14, 14);

				@include break-min($large1) {
					@include font-size(1.8);
					line-height: lh(18, 14);
				}
			}

			.title {
				position: relative;

				p {
					@include font-size(2);
					color: $blue;
					font-weight: $bold-weight;
					letter-spacing: -0.5px;
					line-height: lh(28, 20);
					max-width: 300px;

					@include break-min($large1) {
						@include font-size(3);
						line-height: lh(42, 30);
						padding: 0 65px 0 0;
						max-width: none;
					}
				}
			}

			.post-script p {
				@include font-size(1.2);
				color: $blue;
				line-height: lh(20, 12);
				letter-spacing: -0.5px;
			}

			p {
				margin: 0;
			}
		}

		.arrow-icon {
			position: absolute;
			right: 23px;
			bottom: 23px;

			@include break-min($large1) {
				right: 38px;
				bottom: 41px;
			}
		}
	}
}

.news-callout-header {
	@include grid-margin();
	margin: 0 24px 32px;

	@include break-min($large1) {
		margin-bottom: 32px;
	}

	@include break-min($large4) {
		margin: 32px 0 48px;
	}

	p {
		@include type-h2-refresh();
		color: $blue;
		grid-column: auto / span 12;
		margin: 0;
	}
}

.no-results-container {
	background: $cool-white;
	border-radius: 8px;
	margin: 24px;
	padding: 50px 25px;

	p {
		@include type-p1-refresh();
		color: $blue;
		font-weight: bold;
		margin: 0;
		text-align: center;
	}
}

.article-results-list {
	max-width: 1124px;
	margin: 0 auto;
	position: relative;

	@include break-min($large1) {
		margin: 0 auto;
	}

	.news-callout.full-width-view {
		max-width: none;
	}
}

.results-filter-wrapper {
	position: relative;
	max-width: 1124px;
	margin: 42px auto inherit;

	@include break-min($large1) {
		margin: 98px auto inherit;
	}

	.news-callout-header ~ .filter-modal {
		.link-wrapper {
			position: absolute;
		}
	}
}
