@mixin classic-callout-container-heights {
	@include break-min($medium4) {
		min-height: 430px;
	}
}

@mixin card-classic-container-heights() {
	@include break-min($medium4) {
		min-height: 410px;
	}
}

@mixin card-overlay-container-heights() {
	@include break-min($medium4) {
		min-height: 430px;
	}

	@include break-min($large4) {
		min-height: 342px;
	}
}
