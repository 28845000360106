.header-img-callout {
	&.indent-text-100 {
		@include break-min($large1) {
			padding: 0 0 0 100px;
		}

		@include break-min($huge2) {
			padding: 0 0 0 350px;
		}
	}
}
