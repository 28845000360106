// Utils:
@import "utils/pwo-modals-form-error";
@import "utils/remove-use-location";
// Subcomponents:
@import "subcomponents/pwo-modal-last-ordered";
@import "subcomponents/locations-dropdown";
@import "subcomponents/pwo-modal-search";
@import "subcomponents/pwo-modals-restaurant-label";
@import "subcomponents/use-my-location";

// Variations found at bottom to override master styles

.pwo-modal {
	@extend .base-modal;
	border-radius: 8px;
	max-width: 500px;

	@include break-min($large1) {
		margin: 0 auto;
		padding: 0 42px;
	}

	&.signedIn {
		padding: 0;
		margin-bottom: 0;

		.content {
			padding: 64px 0 0;
		}

		h2,
		.pwo-modal-search {
			padding: 0 15px;

			@include break-min($large1) {
				padding: 0 42px 0;
			}
		}
	}

	.content {
		padding: 64px 15px 24px;

		@include break-min($large1) {
			padding: 64px 0 24px;
		}
	}

	h2 {
		@include font-size(2.4);
		color: $blue;
		font-weight: $bold-weight;
		letter-spacing: -0.5px;
		line-height: lh(34, 24);
		margin: 0 0 16px 0;
		text-align: center;
		max-width: 500px;

		@include break-min($large1) {
			@include font-size(3.6);
			line-height: lh(42, 36);
			margin-bottom: 32px;
		}
	}

	.pwo-last-ordered {
		display: none;
	}

	.secondary {
		background: $cool-white;
		border-radius: 8px;
		padding: 30px 15px 21px;

		@include break-min($large1) {
			padding: 20px 36px 30px;
		}

		.pwo-modal-search {
			padding: 0;

			.user-input {
				background: $white;
			}

			.use-location {
				margin: 0 0 0 13px;
			}
		}
	}
}

// Variations
@import "variations/favorited-restaurants-yext";
