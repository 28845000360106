@mixin title-text-color($color) {
	.title-text {
		> *:first-child {
			color: $color;
		}
	}
}

.header {
	&__red {
		@include title-text-color($red);
	}

	// Default value set on component
	&__blue {
		@include title-text-color($blue);
	}

	&__white {
		@include title-text-color($white);
	}
}
