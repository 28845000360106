.classic-callout {
	@extend .bc-video-container;
	@include bg-color();
	@include text-color-options();
	grid-auto-rows: 1fr;
	grid-row: 2;
	margin-bottom: 60px;
	position: relative;

	@include break-max($large1) {
		grid-auto-rows: auto;
	}

	.optional-icon {
		position: absolute;
		width: auto;
		height: auto;
		top: 50%;
		left: 50%;
		z-index: 1;
		transform: translate(-50%, -50%);

		@include break-max($small4) {
			right: 0 !important;
			transform: translateY(-50%);
			left: unset;
		}
	}

	&.hide-controls {
		.video-container {
			@include remove-bc-controls();
		}

		.overlay {
			display: block;
		}
	}

	@include break-min($small4) {
		display: flex;
	}

	@include break-min($medium4) {
		padding: 0;
	}

	@include break-min($huge4) {
		column-gap: 24px;
		justify-content: center;
	}

	&.btn__white {
		.btn {
			@extend .btn-round--white;
		}
	}

	> * {
		> * {
			@include break-min($medium1) {
				align-self: flex-start;
			}
		}
	}

	img {
		height: 100%;
		width: 100%;
	}

	.video-container,
	.img-container {
		grid-column: col-start 1 / span 4;
		grid-row: 1;
		height: 300px;
		position: relative;

		@include break-min($medium4) {
			grid-column: col-start 5 / span 8;
			max-height: none;
			height: 100%;
			padding: 0;
		}

		@include break-min($large4) {
			grid-column: col-start 7 / span 6;
		}

		@include break-min($huge4) {
			margin-left: -24px;
		}

		img {
			@include absolute-center();
			height: 100%;
			position: absolute;
			object-fit: cover;
		}
	}

	.video-container {
		.player-wrapper {
			.overlay {
				img {
					margin: auto;
				}
			}
		}
	}

	.mobile-img {
		@include break-min($medium1) {
			display: none;
		}
	}

	.desktop-img {
		@include break-max($medium1) {
			display: none;
		}
	}

	.message-container {
		background: $cool-white;
		grid-column: col-start 1 / span 4;
		grid-row: 2;
		height: fit-content;
		padding: 32px;

		.body-text {
			margin-bottom: 32px;
		}

		.btn-container {
			a {
				margin-top: 0;
			}
		}

		@include break-min($medium4) {
			align-self: center;
			display: flex;
			flex-direction: column;
			grid-row: 1;
			height: 100%;
			padding: 46px;
			// padding: 0;
		}

		@include break-min($large4) {
			grid-column: col-start 1 / span 6;
			padding: 72px;
		}
	}

	.icon-container {
		@extend .icon-container-callout;
	}

	.title-text {
		@extend .title-text-callout;
	}

	.body-text {
		margin-bottom: 10px;

		@include break-min($medium4) {
			margin-bottom: 60px;
		}

		p {
			@include type-p2-refresh();
			margin: 0;

			@include break-min($medium4) {
				@include type-p1-refresh();
			}
		}
	}

	.btn {
		margin: 16px 0 0 0;
		@include btn-round;
		@include btn-primary-red;

		@include break-min($large1) {
			margin: 32px 0 0 0;
		}
	}

	&.rounded-video {
		.video-container {
			border-radius: 15px;
		}
	}
}
