.header-field {
	@include text-color-options();
	@include font-weight-options();
	@include add-margin();

	&.align-right {
		text-align: right;
	}
	&.align-center {
		text-align: center;
	}

	> *:first-child {
		@include type-b1();
		margin: 0;
	}
}
