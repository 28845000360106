.signature-drop-ineligible-user {
	.frame {
		height: 100vh;
	}

	.signature-drop-headline-text {
		@extend .sig-drop-headline-base-margin;
		@extend .full-height-align-center;

		margin: 0;
	}
}
