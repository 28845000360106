.gift-card-form {
	@extend .community-form;
	max-width: 1000px;
	padding: 25px;
	width: auto;

	@include break-min($large1) {
		padding: 25px 0 0;
	}

	.form-group {
		&.select {
			@extend .global-form-select;

			select {
				border: 1px solid $grey;
				padding: 25px 17px 4px 16px;
			}
		}

		.radio-input {
			@extend .global-form-radio;
		}

		.input__label {
			left: 17px;
		}

		input[type="file"] {
			padding: 25px 17px 4px 16px;
		}

		.email-verify-div {
			display: none;
			margin: 15px 0 0;

			input {
				padding-top: 4px;
			}
		}
		.acknowledge-wrapper {
			.hasError {
				@extend .global-form-error;
			}

			input {
				padding: 4px 17px 4px 16px;
			}
		}

		&.flag-check {
			@extend .cp-checkbox;
			padding: 0;

			input {
				margin: 0;
			}

			label {
				@include font-size(1.6);
				letter-spacing: -0.25px;
				line-height: lh(19, 16);
			}
		}

		&.add-details-wrapper {
			&.hasError {
				@extend .global-form-error;

				.error-container {
					label {
						color: $red;
					}
				}
			}
		}
	}

	&.suspicious-activity {
		.form-group {
			margin-bottom: 20px;
		}
	}
}
