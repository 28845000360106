.ingredient-list {
	background: $cool-white;
	border-radius: 16px;
	padding: 52px;

	@include break-min($large1) {
		max-width: 460px;
		min-width: 460px;
	}

	.title {
		p {
			@include font-size(2);
			color: $blue;
			font-weight: $bold-weight;
			line-height: 100%;
			letter-spacing: -0.2px;
			margin: 0 0 18px 0;
		}
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;

		li {
			@include font-size(1.8);
			color: $blue;
			line-height: lh(28, 18);
			letter-spacing: -0.5px;
			padding: 19px 0;
			display: flex;
			justify-content: space-between;
			gap: 0 16px;

			&.active {
				color: $red;
			}

			p {
				margin: 0;
			}

			@include break-min($large1) {
				&:first-child {
					display: flex;
					max-width: 445px;
					justify-content: space-between;
					align-items: center;

					p {
						width: 60%;
					}
				}
			}

			@include break-max($large1) {
				p {
					margin-bottom: 15px;
				}

				a {
					display: block;
					width: 100% !important;
				}
			}

			&:not(:last-child) {
				border-bottom: 1px solid rgba(0, 79, 113, 0.1);
			}

			&:last-child {
				padding-bottom: 0;
			}

			.btn-round {
				width: fit-content;
			}
		}
	}

	@include break-max($large1) {
		max-width: 100%;
		width: 100%;
	}
}
