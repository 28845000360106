.lto-card {
	@include grid-margin();
	// need to override grid-layout class
	display: flex !important;
	position: relative;
	flex-direction: column;
	gap: 16px;
	justify-content: center;

	@include break-min($medium4) {
		display: grid !important;
		gap: 24px;
	}

	.intro-card {
		background: $warm-white;
		border-radius: 32px;
		overflow: hidden;
		padding: 24px 16px;
		position: relative;

		@include break-min($medium4) {
			background: $white;
			border: 3px solid $red;
			border-radius: 32px;
			padding: 76px 30px;
			grid-column: col-start 1 / span 3;
			margin: auto;
			text-align: center;
		}

		@include break-min($large4) {
			grid-column: col-start 1 / span 3;
		}

		.mobile-link {
			@include break-min($medium4) {
				pointer-events: none;
			}
		}

		.title {
			padding-right: 25px;

			> :first-child {
				@include type-h3-refresh();
				color: $grey-p;
				margin: 0;

				@include break-min($medium4) {
					@include font-size(3.6);
					color: $red;
					font-weight: $bold-weight;
					line-height: lh(46, 36);
				}
			}

			p {
				@include font-size(1.4);
				line-height: lh(23px, 14);
				letter-spacing: -0.25px;
				text-align: left;
			}
		}

		.btn-round {
			@extend .btn-secondary;
			display: none;

			@include break-min($medium4) {
				display: block;
			}
		}

		.mobile-chevron {
			align-items: center;
			display: flex;
			bottom: 0;
			margin: auto;
			right: 16px;
			top: 2px;

			@include break-min($medium4) {
				display: none;
			}
		}
	}

	.product-card {
		align-items: center;
		background: $warm-white;
		border-radius: 32px;
		overflow: hidden;
		padding: 16px;

		@include break-min($medium4) {
			display: flex;
			padding: 0 32px;
		}

		a {
			align-items: center;
			display: flex;
			position: relative;

			@include break-min($medium4) {
				width: 100%;
			}
		}

		.img-container {
			margin: 0 10px 0 0;
			position: relative;

			@include break-min($medium4) {
				flex: 66.67%;
				height: 100%;
				max-height: 300px;
			}

			.mobile-img {
				height: 24px;
				width: 24px;

				@include break-min($medium4) {
					display: none;
				}
			}

			.desktop-img {
				display: none;

				@include break-min($medium4) {
					// @include absolute-center();
					display: block;
					height: 300px;
					object-fit: cover;
				}
			}

			img {
				height: 100%;
				width: 100%;
			}
		}

		.content {
			flex: 33.33%;

			.banner {
				display: none;
				background: $blue;
				border-radius: 20px;
				margin: 0 auto 8px;
				width: fit-content;

				@include break-min($medium4) {
					display: block;
					padding: 0 16px;
				}

				p {
					@include font-size(1.4);
					color: $white;
					letter-spacing: -0.25px;
					margin: 0;
					text-align: center;
					padding: 5px 0;
				}
			}

			.title {
				padding-right: 25px;

				> :first-child {
					@include type-h3-refresh();
					margin: 0;
					color: $grey-p;

					@include break-min($medium4) {
						@include font-size(2.4);
						color: $blue;
						font-weight: $bold-weight;
						line-height: lh(34, 24);
						letter-spacing: -0.5px;
						text-align: center;
					}
				}
			}
		}
	}

	.mobile-chevron {
		position: absolute;
		right: 0;
		top: 0;

		@include break-min($medium4) {
			display: none;
		}
	}

	&[data-child-count="2"] {
		.product-card {
			@include break-min($medium4) {
				grid-column: auto / span 5;
			}

			@include break-min($large4) {
				grid-column: auto / span 9;
			}

			.content {
				@include break-min($medium4) {
					padding: 0 0 0 32px;
				}
			}
		}
	}

	&[data-child-count="3"] {
		.intro-card {
			@include break-min($medium4) {
				grid-column: auto / span 3;
			}
		}

		.product-card {
			display: block;

			@include break-min($medium4) {
				grid-column: auto / span 4;
				padding: 13px 32px;
			}

			@include break-min($large4) {
				grid-column: auto / span 4;
			}

			.img-container {
				@include break-min($medium4) {
					height: 200px;
					width: 200px;
					margin: 0 auto 16px;
					position: relative;
				}

				img {
					@include break-min($medium4) {
						@include absolute-center();
						height: 100%;
						width: 100%;
						object-fit: cover;
					}
				}
			}
		}
	}
}
