@import "../common/main-nav/main-nav";

.main-nav {
	.iphone & {
		padding-top: $nav-base-height;
		padding-bottom: 120px;
	}

	@media only screen and (max-width: $mobile) {
		padding: $nav-base-height 16px;
	}

	@media only screen and (max-width: $mobile) {
		& > ul {
			li {
				border-bottom: 0;
			}
		}
	}

	@include break-max($large2) {
		justify-content: flex-start;

		& > ul {
			position: relative;
			padding: 0;
		}
	}

	&.no-catering {
		ul {
			@include break-max($large1) {
				li:last-child {
					display: none;
				}
			}
		}
	}

	.order-button-group {
		@media only screen and (max-width: $mobile) {
			flex-flow: column;
			left: 0;
			padding: 0 24px;
			width: 100%;

			a {
				margin: 10px 0;
			}

			.btn-round {
				width: 100%;
			}
		}

		@media screen and (max-width: $mobile) and (min-height: 750px) {
			bottom: 48px;
			position: absolute;
		}

		@include break-min($large1) {
			order: 2;
			margin-right: 24px;
			z-index: 1;

			a {
				padding: 15px 24px;
				line-height: 1.1;
				@include font-size(1.6);

				@include break-min($large3) {
					padding: 15px 44px;
				}
			}

			form {
				display: none;
			}
		}

		@include break-min($sml-desktop) {
			order: 2;
			z-index: 1;
		}
	}

	.location-tools {
		@include break-min($large1) {
			display: none;
		}
	}

	.search {
		@include break-min($large1) {
			order: 3;
		}
	}

	.cfa-one {
		@include break-min($sml-desktop) {
			margin-right: 30px;
		}

		&.logged-in {
			@include break-min($large1) {
				order: 1;
				margin-right: 24px;

				.username {
					padding-left: 4px;
				}
			}
		}

		&.logged-out {
			margin-right: 24px;
		}

		.sign-in {
			button {
				@include font-size(1.6);

				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	&.logged-out {
		> ul {
			padding-top: 75px;
		}

		.cfa-one {
			display: none;
		}

		@include break-min($large1) {
			> ul {
				padding-top: 0;
			}
			.cfa-one {
				display: flex;
			}
		}
	}

	&.logged-in {
		> ul {
			padding-top: 0;
		}

		.cfa-one {
			display: flex;
			flex-flow: column;
		}
	}

	#navitem-menu {
		span {
			display: inline-flex;
			width: 50%;

			@include break-min($large1) {
				width: 100%;
			}
		}

		.icon-arrow-down {
			justify-content: flex-end;
			left: unset;

			@include break-min($large1) {
				display: none;
			}
		}
	}

	.icon-arrow-down {
		&::before {
			@include transform(rotate(0deg));
		}
	}

	.order-online {
		padding: 8px 0;

		@include break-min($large1) {
			padding: 0;
			margin-right: 24px;
		}
	}

	.order {
		order: 2;
	}

	// nav shown overrides
	@media only screen and (max-width: $mobile) {
		.nav-shown & {
			.location-tools {
				padding: 0;
				margin-left: 15px;
				margin-top: 15px;

				button {
					font-family: $primary;
					font-weight: 600;
					margin-left: 5px;
					@include font-size(2.3);
				}
			}

			.location-tools .current-location {
				align-items: center;
				display: flex;
				justify-content: flex-start;
			}

			.current-location {
				&.left-align {
					justify-content: flex-start;
				}
			}

			.cfa-one {
				// sass-lint:disable-block no-duplicate-properties
				margin-top: 59px;
				flex-flow: column;

				.username {
					@include font-size(2.3);
					flex: auto;
				}
			}
		}
	}

	// override canada styles
	&.canada {
		.cfa-one {
			a:nth-of-type(2) {
				margin-left: 10px;
			}
		}

		.order-button-group {
			@include break-min($large1) {
				flex-flow: row-reverse;

				a {
					margin-left: 24px;
				}

				#navitem-cfaone {
					form {
						button {
							padding: 16px 44px !important;
						}
					}
				}

				form {
					display: block;
				}
			}
		}
	}
}
