@mixin text-color() {
	&.text-white {
		color: $white;
	}

	&.text-blue {
		color: $blue;
	}

	&.text-red {
		color: $red;
	}
}
