.three-column-with-cta {
	background-color: $white;

	ul {
		list-style: none;
		padding: 0;

		li {
			margin-bottom: 20px;

			@include break-min($large1) {
				margin-bottom: 45px;
			}
		}
	}

	&-headline-container {
		justify-content: center;
		align-items: center;
		margin: auto;
		max-width: 800px;
		padding: 0;
		text-align: center;
		@include flex-flow(column);
		@include flexbox();

		@include break-min($large1) {
			padding: 0 24px;
		}

		h2 {
			@include type-h1();
			color: $blue;
			line-height: lh(54, 48);
			margin: 0 0 32px;
			@include font-size(3.6);

			@include break-min($large1) {
				@include font-size(4.8);
			}
		}

		p {
			line-height: 28px;
			letter-spacing: -0.5px;
			font-weight: 700;
			@include font-size(1.8);
			margin-bottom: 30px;

			@include break-min($large1) {
				margin-bottom: 50px;
			}
		}
	}

	&.gridLayout {
		@include desktop-grid-max-width();
		@include grid-padding();

		.component-wrapper {
			// Justification: need to overwrite rule for rule above to stick
			// eslint-disable-next-line
			padding-left: 0 !important;
			// eslint-disable-next-line
			padding-right: 0 !important;
		}
	}

	.component-wrapper {
		padding-top: 0;
		padding-bottom: 42px;
		margin: 0 auto;
		max-width: 1580px;
		padding: 60px 20px 130px;
		width: auto;

		@include break-min($large1) {
			padding: 100px 30px 160px;
		}

		li {
			padding: 0;
			border: none;

			&:last-child {
				border: none;
				padding-bottom: 0;
			}

			@include break-min($large1) {
				padding: 0 60px;
				border-right: 1px solid $faint-grey;
			}

			.img-container {
				border: 1px solid $iron;
				border-radius: 15px;
				overflow: hidden;

				@include break-min($large1) {
					margin-left: 0;
					margin-right: 0;
				}

				img {
					object-fit: cover;
					width: 100%;
					max-height: 200px;
				}
			}

			.content {
				text-align: left;
				margin-top: 32px;
				padding: 0;
				@include flexbox();
				@include flex-flow(column);

				p {
					margin: 0;
					line-height: 25px;
					font-weight: 700;
					@include font-size(1.6);
				}

				h3 {
					color: $grey;
					line-height: lh(54, 48);
					margin: 0 0 15px;
					font-family: $primary;
					font-weight: 700;
					@include font-size(2.4);
				}
			}

			.cta-container {
				margin-top: 24px;
				align-items: center;
				@include flexbox();

				@include break-min($large1) {
					margin-top: 20px;
				}

				// a {
				// 	margin: 10px 5px;

				// 	&:first-child {
				// 		margin: 0 5px 10px;
				// 	}

				// 	@include break-min($large1) {
				// 		margin: 0 5px;
				// 	}
				// }
			}
		}
	}

	.component-cta-container {
		margin-top: 0;
		justify-content: center;
		@include flexbox();

		@include break-min($large1) {
			margin-top: 50px;
		}
	}

	.see-more-btn {
		margin: 0;
		width: 50%;

		@include break-min($large1) {
			margin: -43px 0 0;
			width: auto;
		}
	}

	&.product-landing {
		max-width: 1348px;
		margin: auto;

		@include break-min($max-width) {
			max-width: 1254px !important;
		}

		@include break-min($huge2) {
			max-width: 1348px !important;
		}

		.component-wrapper {
			padding: 60px 24px 80px;

			@include break-min($large1) {
				padding: 60px 70px 80px;
			}

			ul {
				display: flex;

				@include break-min($large1) {
					display: grid;
					grid-template-columns: repeat(12, [col-start] 1fr);
					grid-auto-flow: row;
					grid-gap: 16px;
				}

				li {
					grid-column: auto/span 4;
					border-right: none;

					@include break-min($large1) {
						padding: 0;
					}

					.content {
						p {
							display: block;
							font-weight: normal;
						}
					}
				}
			}
		}

		h2 {
			@include font-size(2.8);
			margin: 0 0 32px;

			@include break-min($large1) {
				@include font-size(4.8);
			}
		}

		p {
			color: $grey;
			font-weight: $regular-weight;
		}

		ul {
			li {
				width: 100%;

				.img-container {
					height: 262px;

					img {
						max-height: none;
						height: 100%;
					}
				}
			}
		}

		.content {
			margin-top: 16px !important; //es lint justify next line

			> *:first-child {
				@include font-size(2);
				color: $blue;

				@include break-min($large1) {
					@include font-size(3);
				}
			}

			p {
				display: none;
			}
		}

		.cta-container {
			margin-top: 16px !important;

			a {
				width: 50%;

				@include break-min($large1) {
					width: auto;
				}
			}
		}

		.component-cta-container {
			display: none;
		}
	}

	&.no-padding {
		.component-wrapper {
			padding-top: 0px;
		}
	}
}
